import { Apis, get, post } from '.';

export const Service = {
  login: async (obj) => {
    let result = await post(Apis.login, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  trainingRegistration: async (obj) => {
    let result = await post(Apis.trainingRegistration, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  postServiceOrder: async (obj) => {
    let result = await post(Apis.postServiceOrder, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getPreRequisiteUrl: async (obj) => {
    let result = await post(Apis.getPreRequisiteUrl, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getServiceOrderPreRequisite: async (obj) => {
    let result = await post(Apis.getServiceOrderPreRequisite, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  updatePreRequisitesByOrderID: async (Id, obj) => {
    let result = await post(Apis.updatePreRequisitesByOrderID + `?OrderId=${Id}`, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  updateUserProfilePic: async (Id, obj) => {
    let result = await post(Apis.updateUserProfilePic + `?UserId=${Id}`, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getTraining: async (limit, page) => {
    let result = await get(Apis.getTraining + `?Limit=${limit}&Page=${page}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getTrainingDetail: async (id) => {
    let result = await get(Apis.getTrainingDetail + `?TrainingId=${id}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getCategory: async () => {
    let result = await get(Apis.getCategory);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getTrainingsById: async (limit, page, id) => {
    let result = await get(Apis.getTrainingsById + `?Limit=${limit}&Page=${page}&CatId=${id}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getStates: async () => {
    let result = await get(Apis.getStates);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getServices: async () => {
    let result = await get(Apis.getServices);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getServiceDetails: async (StateId, ServiceId) => {
    let result = await get(Apis.getServiceDetails + `?ServiceId=${ServiceId}&StateId=${StateId}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getInvoiceDetails: async (Id) => {
    let result = await get(Apis.getInvoiceDetails + `?OrderId=${Id}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getOrderDetails: async () => {
    let result = await get(Apis.getOrderDetails);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getPreRequisite: async (OrderId) => {
    let result = await get(Apis.getPreRequisite + `?OrderId=${OrderId}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getPreRequisiteDetails: async (OrderId) => {
    let result = await get(Apis.getPreRequisiteDetails + `?OrderId=${OrderId}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getUserInfo: async (userId) => {
    let result = await get(Apis.getUserInfo + `?UserId=${userId}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
  checkEmail: async (email) => {
    let result = await get(Apis.checkEmail + `?Email=${email}`);
    if (result.status === 200) return result.data;
    else throw result;
  },

}