import React from 'react';
import { Container, Typography, Grid, Box, AccordionSummary, AccordionDetails } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/system';
import Images from 'assets/Images';

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function TaxFilling() {
  return (
    <Box sx={{ py: 7 }}>
      <Container>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item md={6}>
            <Typography variant="h3" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow' }} >Tax Return Filing Services</Typography>
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography sx={{ fontWeight: 'bold' }}>Tax Return Filing to IRS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  <li style={{ marginBottom: 2 }}>Sales Tax Return
                    <ul>
                      <li>$75/Return</li>
                      <li>Filed Monthly/Quarterly/ Bi-Annually/Annually</li>
                      <li>Tax rates depend on the state of the LLC registration</li>
                    </ul>
                  </li>
                  <li style={{ marginBottom: 2 }}>Federal Tax Return
                    <ul>
                      <li>$250/Return</li>
                      <li>Filed annually</li>
                      <li>Tax rate 21% - 35% on Profit</li>
                    </ul>
                  </li>
                  <li>State Tax Return
                    <ul>
                      <li>$75/Return</li>
                      <li>Filed annually</li>
                      <li>Tax rates depend on the state</li>
                    </ul>
                  </li>
                </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography sx={{ fontWeight: 'bold' }}>Filing Tax Returns to HMRC</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  <li style={{ marginBottom: 2 }}>VAT Return Filing</li>
                  <li>Self-Assessment Tax Returns</li>
                </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography sx={{ fontWeight: 'bold' }}>Filing Tax Returns to FBR</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Income Tax return Filing.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={5}>
            <img src={Images.taxFillingReturn} alt="Tax Filling Return"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}


export default TaxFilling