//* Import from MUI
import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, OutlinedInput, MenuItem, FormControl, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Done, LooksOneRounded, LooksTwoRounded, StarBorderPurple500Rounded } from '@mui/icons-material/';

//* Import Component
import colors from 'app/style/colors';
import Images, { Loader } from 'assets/Images';
import { Link } from 'react-router-dom';
import Layout from 'app/layout/Layout';
import { Service } from 'app/config/service';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

// const packageIncludes = [
//   'Preparing & Filing the Articles of Organization',
//   'Unlimited Name Searches',
//   'FREE Registered Agent Service for a year!',
//   'EIN Business Tax Number',
//   'IRS Form 2553',
//   'Operating Agreement',
// ];

const useStyles = makeStyles({
  text: {
    textDecoration: 'none',
    color: 'black',
    '&:hover': { color: colors.primary },
  }
})

function StartBusiness() {

  const classes = useStyles()

  const [stateData, setStateData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceDetailsData, setServiceDetailsData] = useState([]);

  //* State for extracting Description Data from Api
  const [descriptionData, setDescriptionData] = useState([]);
  //* State for extracting Requirement Data from Api
  const [requirementsData, setRequirementsData] = useState([]);

  const [corporationName, setCorporationName] = useState("");
  const [stateName, setStateName] = useState("");

  const [selectedPackage, setSelectedPackage] = useState({
    ServiceId: "",
    StateId: ""
  });

  const [loader, setLoader] = useState(false);

  //* Get Service Details API
  const getServiceDetails = async (serviceID, stateID) => {
    try {
      setLoader(true)
      const { Data } = await Service.getServiceDetails(stateID, serviceID)
      setServiceDetailsData(Data)
      const arrDataDescription = Data[0]?.Description.split(".")
      setDescriptionData(arrDataDescription)
      const arrDataRequirements = Data[0]?.PreRequisities.split(".")
      setRequirementsData(arrDataRequirements)
      console.log('file: StartBusiness.js => line 69 => getServiceDetails => Data', Data)
    } catch (error) {
      console.log('file: StartBusiness.js => line 118 => getServiceDetails => error', error)
    }
    finally {
      setLoader(false)
    }
  }

  const handleCorporation = (data) => {
    setCorporationName(data.ServiceName);
    setSelectedPackage({ ServiceId: data.Id, StateId: selectedPackage.StateId ? selectedPackage.StateId : "" })
    getServiceDetails(data.Id, selectedPackage.StateId)
  };
  const handleState = (data) => {
    setStateName(data.StateName)
    setSelectedPackage({ ServiceId: selectedPackage.ServiceId, StateId: data.Id })
    getServiceDetails(selectedPackage.ServiceId, data.Id)
  };

  //* Get State API
  const getStates = async () => {
    try {
      const { Data } = await Service.getStates()
      setStateData(Data)
    } catch (error) {
      console.log('file: StartBusiness.js => line 118 => getStates => error', error)
    }
  }


  //* Get Service API
  const getServices = async () => {
    try {
      const { Data } = await Service.getServices()
      setServiceData(Data)
    } catch (error) {
      console.log('file: StartBusiness.js => line 118 => getServices => error', error)
    }
  }

  useEffect(() => {
    getServices()
    getStates()
  }, [])

  //* Scroll Top Service Card Package
  useEffect(() => {
    const element = document.getElementById("pck")
    element && element?.scrollIntoView({ behavior: "smooth" })
  }, [stateName, corporationName])


  return (
    <Layout>
      <Box textAlign='center' bgcolor={colors.antiqueWhite} >
        <Typography variant="h3" sx={{
          fontWeight: 900, pt: 6, color: colors.black
        }}>
          Start your business with confidence
        </Typography>
        <Typography variant="h6" sx={{ my: 2 }} >
          Join over 500,000 happy business owners. Get started by choosing your entity type and state of formation.
        </Typography>

        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={3} style={{
            alignItems: 'center', marginTop: 25
          }}>

            <img src={Images.review} alt="Review"
              style={{ borderRadius: "50%", width: '200px', height: '200px' }} />


            <Grid sx={{ display: 'flex', flexDirection: 'column' }} >
              <Typography component={Link} to="/start-business" className={classes.text}
                sx={{ display: 'block', ml: 2 }}>
                More trusted, Verified reviews
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <StarBorderPurple500Rounded sx={{ color: colors.primary }} />
                <StarBorderPurple500Rounded sx={{ color: colors.primary }} />
                <StarBorderPurple500Rounded sx={{ color: colors.primary }} />
                <StarBorderPurple500Rounded sx={{ color: colors.primary }} />
                <StarBorderPurple500Rounded sx={{ color: colors.primary }} />
              </Box>
              <Typography sx={{ pl: 1 }}>27,303 ratings</Typography>
            </Grid>
          </Grid>
          {/* selector section */}
          <Grid item xs={10} md={4} sx={{ my: 'auto' }}>
            {/* Corporation Drop Down */}
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Select
                displayEmpty
                value={corporationName}
                // onChange={handleCorporation}
                input={<OutlinedInput sx={{ bgcolor: 'white', fontSize: '1.2rem' }} />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (<Box sx={{ display: 'flex' }}>
                      <LooksOneRounded sx={{ fontSize: '2rem', color: colors.primary }} />
                      <Typography sx={{ fontSize: '1.3rem', pl: 2, color: colors.textPrimary }}>
                        Select Service
                      </Typography>
                    </Box>);
                  }
                  return (<Box sx={{ display: 'flex' }}>
                    <LooksOneRounded sx={{ fontSize: '2rem', color: colors.primary }} />
                    <Typography sx={{ fontSize: '1.3rem', pl: 2 }}>
                      {selected}
                    </Typography>
                  </Box>);
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {serviceData.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.ServiceName}
                    onClick={() => handleCorporation(item)}
                  //style={getStyles(name, corporationName, theme)}
                  >
                    {item.ServiceName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {/* State Drop Down */}
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Select
                displayEmpty
                value={stateName}
                //onChange={handleState}
                input={<OutlinedInput sx={{ bgcolor: 'white', fontSize: '1.2rem' }} />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Box sx={{ display: 'flex' }}>
                        <LooksTwoRounded sx={{ fontSize: '2rem', color: colors.primary }} />
                        <Typography sx={{ fontSize: '1.3rem', pl: 2, color: colors.textPrimary }}>
                          State of Formation
                        </Typography>
                      </Box>
                    );
                  }
                  return (<Box sx={{ display: 'flex' }}>
                    <LooksTwoRounded sx={{ fontSize: '2rem', color: colors.primary }} />
                    <Typography sx={{ fontSize: '1.3rem', pl: 2 }}>
                      {selected}
                    </Typography>
                  </Box>);
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {stateData.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.StateName}
                    onClick={() => handleState(item)}
                  // style={getStateStyles(item.StateName, selectedData, theme)}
                  >
                    {item.StateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ py: '30px' }}>State-specific pricing will display below once
          <br />your selection has been made</Typography>

      </Box >
      {stateName &&
        <Fragment>
          {/* ========== Pricing Card ========== */}
          <span id="pck"></span>
          {loader ? (
            <Box sx={{ textAlign: "center" }} >
              <Loader />
            </Box>
          ) : (
            serviceDetailsData.length > 0 ? (
              <Grid container sx={{
                display: 'flex',
                mt: '30px', boxShadow: 3,
                borderRadius: '30px', p: 3, width: '70%', mx: "auto"
              }}>
                <Grid item xs={12} md={5} sx={{ textAlign: 'center', p: 2, my: 'auto' }} >
                  <Typography variant='h4' sx={{ fontWeight: 'bold', color: colors.black }}>Featured</Typography>
                  <Typography variant='body1' sx={{ color: colors.textPrimary }}>Full service features<br />
                    at the best value</Typography>
                  <img src={Images.pricing} alt="Review"
                    style={{
                      width: '150px', borderRadius: '50%',
                      textAlign: "left", marginTop: '30px', marginLeft: '10px'
                    }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: colors.primary }}>${serviceDetailsData[0]?.ServiceCharge}</Typography>
                  <Typography sx={{ color: colors.textPrimary }}>One time payment</Typography>
                  <Button variant="contained" component={Link} to="/service-payment"
                    state={{
                      serviceId: serviceDetailsData[0]?.Id,
                      serviceState: stateName,
                      serviceName: corporationName,
                      serviceCharges: serviceDetailsData[0]?.ServiceCharge
                    }}
                    sx={{ borderRadius: '15px', mt: 2, height: '50px' }}>
                    Get the Service Package
                  </Button>
                  {/* <Typography sx={{ my: 3 }}>
                <li
                  style={{ display: 'flex', justifyContent: 'space-between', listStyle: 'none', marginTop: 10 }}>
                  <HelpOutlineRounded sx={{ color: colors.primary }} />
                  Package Fee
                  <span >$299</span>
                </li>
                <li
                  style={{ display: 'flex', justifyContent: 'space-between', listStyle: 'none', marginTop: 10 }}>
                  <HelpOutlineRounded sx={{ color: colors.primary }} />
                  State Fee
                  <span >$247</span>
                </li>
              </Typography> */}

                </Grid>

                <Grid item xs={12} md={7} sx={{ textAlign: 'center', p: 2 }}>
                  <Typography sx={{ bgcolor: colors.primary, color: colors.white, borderRadius: '5px', py: 0.75 }}>
                    Package Includes:</Typography>
                  <Typography variant="body1" sx={{ textAlign: 'left', my: 3, color: colors.textPrimary }}>
                    {descriptionData.map((item, index) => (
                      <li key={index} style={{ listStyleType: "none" }}>
                        <Done style={{ color: colors.primary, paddingRight: '12px', p: 1 }} />
                        {item}
                      </li>
                    ))}
                    {/* {serviceDetailsData[0]?.Description} */}
                  </Typography>
                  <Typography sx={{
                    bgcolor: colors.primary, mt: 3,
                    color: colors.white, borderRadius: '5px', py: 0.75
                  }}>
                    Requirements:</Typography>
                  <Typography variant="body1" sx={{ textAlign: 'left', my: 3, color: colors.textPrimary }}>
                    {requirementsData.map((item, index) => (
                      <li key={index} style={{ listStyleType: "none", color: colors.textPrimary }}>
                        <Done style={{ color: colors.primary, paddingRight: '12px', p: 1 }} />
                        {item}
                      </li>
                    ))}
                    {/* {serviceDetailsData[0]?.PreRequisities} */}
                  </Typography>

                </Grid>
              </Grid>) : (
              <Box sx={{
                mt: '30px', boxShadow: 3,
                borderRadius: '30px', p: 3, width: '70%', mx: "auto", textAlign: 'center'
              }}>
                <img src={Images.notFound} alt="Item not found"
                  width='170px' style={{ textAlign: 'center' }}
                />
                <Typography variant="h4" color={colors.primary}>
                  No Package Found!
                </Typography>
              </Box>
            )

          )}
        </Fragment>
      }
    </Layout>

  )
}

export default StartBusiness
