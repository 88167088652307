import React from 'react';
import { Box, Typography, Grid, Container, Button, CardContent, Card } from '@mui/material';

import colors from 'app/style/colors';

function FeaturedService() {
  return (
    <Box sx={{ py: 10, bgcolor: '#F9FAFB' }}>
      <Container>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item md={4}>
            <Typography variant="h3" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.primary }} >Featured Services</Typography>
            <Typography variant="h6" sx={{ color: colors.textPrimary }} >
              Since wire-frame renderings are relatively simple and fast to calculate, they are often used in cases
            </Typography>
            {/* <Button
              variant="contained"
              sx={{
                py: 1.5,
                px: 2,
                mt: 5,
                borderRadius: 2,
              }}
            >
              Explore More
            </Button> */}
          </Grid>
          <Grid item md={7} container spacing={2} >
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  height: 400,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.primary,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >
                    LLC formation
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary, fontWeight: 'bold' }} >
                    Get your LLC registered anywhere in the US
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    We are best at filing it accurately and know how to handle all the legal matters for you.
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    We have made the service packages super easy so that you can choose the most suitable one for yourself at very reasonable charges.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  height: 400,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.primary,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >
                    Filling of Tax
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary, fontWeight: 'bold' }} >
                    We’ll file your taxes so that you can focus on your business growth only.
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    Filing your taxes can be a big hassle due to varying procedures in a different state of the US and a different process in the UK and Pakistan.
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    Our team of experts is managing these legal matters knows all about tax-related technicalities and how to handle them.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  height: 400,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.primary,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >
                    Amazon ID Creation
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary, fontWeight: 'bold' }} >
                    Get a fully activated Amazon account for yourself.
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    Creating an Amazon account is a bit technical, and we know how to work that out and hand a fully activated account in no time.
                  </Typography>
                  <Typography sx={{ fontSize: 14, my: 0.6, color: colors.textPrimary }} >
                    We have created hundreds of Amazon accounts so far where our customers are doing business on this huge platform.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturedService;