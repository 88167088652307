import React, { useState } from 'react';
import { AddToPhotos, ArrowForward, } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link } from 'react-router-dom';

//*Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';

function PakistanFormation() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}
        <Grid container sx={{ bgcolor: colors.lightGray, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={6}
          >
            <Typography variant="h4" sx={{
              color: colors.black, textAlign: 'center', mt: 10,
              fontWeight: 'bold', mx: 2
            }}>
              Company Registration in Pakistan
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '140px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px',
                mb: 1
              }}
            />

            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '100px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px'
              }}
            />

            <Typography sx={{ my: 3, ml: 3 }}>Registering a company in Pakistan is done through the Security and Exchange Commission of Pakistan (SECP) following a few simple steps and filling up a challan of a few hundred. You can do it on your own or contact us and get it done correctly and just on time.</Typography>

            <Button
              component={Link} to='/start-business'
              variant="contained"
              endIcon={<ArrowForward />}
              sx={{ my: 3, borderRadius: 8, mx: 8, py: 2, fontWeight: 'bold', fontSize: '14px', width: '310px' }}
            >
              Form Your Pakistani Company
            </Button>

          </Grid>
          <Grid item xs={12} md={5} sx={{
            background: `url(${Images.companyFormationBanner})`, mr: 3,
            position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }
          } />
        </Grid>

        <Grid sx={{ my: 7 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1, color: colors.black }}>There are three types of companies you can register in Pakistan: </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '200px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
              <Grid item xs={12} md={3.5}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1, height: '200px'
                }}>
                <Box >
                  <Box sx={{ textAlign: 'justify', mt: 3 }}>


                    <List component="nav" >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Single Limited Company" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Private Limited Company" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 2}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 2)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Public Limited Company" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1
                }}
              >
                {
                  selectedIndex === 0 &&
                  <Box>
                    <Typography variant="h4"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Single Limited Company</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary }}>
                      <Typography sx={{ my: 1.5 }}>When a single member forms a private limited company as per the companies act 2017, it is called a single limited company. </Typography>

                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 1 &&
                  <Box>
                    <Typography variant="h4"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Private Limited Company</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Typography
                        sx={{ mt: 2, mb: 4 }}
                      >This can be formed when two or more persons legally associate and subscribe to the Memorandum of Association to start a company under the companies act 2017. </Typography>

                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 2 &&
                  <Box>
                    <Typography variant="h4"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Public Limited Company</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Typography
                        sx={{ mt: 2, mb: 4 }}
                      >When three or more persons legally associate to form a limited company and subscribe to the Memorandum of Association to form a company under the companies act 2017.</Typography>

                    </Box>
                  </Box>
                }
              </Grid>
              <Grid sx={{ my: 7, bgcolor: colors.lightGray, p: 2 }}>
                <Typography variant="h5" color={colors.black}
                  sx={{ my: 2, fontWeight: 'bold' }}>What’s needed for company registration in Pakistan:</Typography>
                <ol style={{ lineHeight: 2.5, fontSize: '18px', color: colors.textPrimary }}>
                  <li>Decide a name and check its availability on the SECP website. Don’t forget to check for prohibited terms stated in section 10 of the act and regulation 4 of companies regulations 2017.</li>
                  <li>Details of the company director(s) or shareholders.</li>
                  <li>Correspondence address.</li>
                  <li>CNIC if you are a Pakistani national and passport if you are a foreigner.</li>
                  <li>Documents needed include Article of association and Memorandum of association.</li>
                  <li>Pay the registration fee using your debit/credit card.</li>
                </ol>
              </Grid>
            </Grid>
          </Box>
        </Grid >
      </Container >
    </Layout >
  )
}

export default PakistanFormation

