import React, { useState } from 'react';
import { AddToPhotos } from '@mui/icons-material'
import { Box, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

//*Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';

function FillingTaxReturn() {

  const [selectedIndex, setSelectedIndex] = useState(0);


  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}

        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningSectionBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }
        }>


          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            Filling Tax Return
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '170px',
              padding: '1.5px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '120px',
              padding: '1.3px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>


        <Grid sx={{ my: 7 }}>
          <Typography variant="h4"
            sx={{ my: 4, color: colors.black, texAlign: "center", fontWeight: 'bold' }}>
            Let our dedicated team handle your tax matters precisely without any delay!
          </Typography>
          <Typography sx={{ mb: 1, color: colors.textPrimary }}>Tax return filing and keeping track of it is crucial for your registered business to avoid unnecessary complications and penalties. </Typography>
          <Typography sx={{ mb: 1, color: colors.textPrimary }}>Filing tax returns can be an overwhelming task for you because of varying requirements as per the IRS and the state you have your business registered. </Typography>
          <Typography sx={{ mb: 1, color: colors.textPrimary }}>Our highly competitive legal team knows how to handle it on time so that you don’t miss a deadline and only focus on your business growth.</Typography>
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
              <Grid item xs={12} md={3.5}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1, height: '200px'
                }}>
                <Box >
                  <Box sx={{ textAlign: 'justify', mt: 3 }}>


                    <List component="nav" >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Filing Tax Returns to IRS" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Filing Tax Returns to HMRC" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 2}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 2)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Filing Tax Returns to FBR" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1
                }}
              >
                {
                  selectedIndex === 0 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Filing Tax Returns to IRS</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary }}>
                      <Typography sx={{ my: 2, fontSize: "14px" }}> Your LLC is treated as a sole proprietorship or a partnership by the IRS, depending on if it is a single member or a multi-member LLC. One member LLC is treated as a sole proprietor by the IRS that means the LLC itself will pay no taxes and does not require filing tax returns with the IRS. However, reporting all LLC profit and loss on Schedule C and submitting it with their 1040 tax return is necessary for single-member LLC owners.</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>On the other hand, the IRS treats multi-member LLCs as partnerships for tax purposes where the LLC itself does not pay income tax. In contrast, each of the members pays taxes on their profit share with their personal income tax returns attaching a Schedule E along. There are various important aspects to be considered in terms of taxes and tax reporting in a co-owned LLC, and the members should be aware of handling the taxation process properly.   </Typography>
                      <Typography variant="h6" sx={{ color: colors.black, mt: 2, fontWeight: 'bold' }}>1.	Federal Tax Return</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '200px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ my: 2, fontSize: "14px" }}>You need to file federal tax returns annually only if you exceed certain income levels. Federal income tax has seven tax rates from 10 percent to 37 percent, while corporates or small businesses are taxed at a rate of 21%-35% on their net profit. It is essential for you to keep a financial record with all the necessary documents to file tax returns on time without delay. </Typography>
                      <Typography variant="h6" sx={{ color: colors.black, mt: 2, fontWeight: 'bold' }}>2.	State Tax Return</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '180px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ my: 2, fontSize: "14px" }}>State tax returns are also filed annually, and tax rates vary from one state to another. Most states impose taxes on business profits the same way as IRS does. Although LLC itself does not pay any taxes, the LLC owner pays taxes to the state on personal returns. Some states like California impose additional taxes based on the profit that an LLC makes besides the personal taxes that the owner pays. Similarly, there are some states where annual fees are imposed on the LLC irrespective of its income. This is called a renewal fee or annual registration fee, and you should understand these additional taxes and fees before filing a company in a particular state.</Typography>
                      <Typography variant="h6" sx={{ color: colors.black, mt: 2, fontWeight: 'bold' }}>3.	Sales Tax Return </Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '180px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ my: 2, fontSize: "14px" }}>Every person or entity engaged in business needs to register for sales tax when involved in selling tangible personal property and select services. A person requires a sales permit or sales tax license before making sales or providing the services.  The sales tax is collected from the customers, and then this collected tax is paid to the government tax authorities. There are no standards for sales tax rates in the US, and sales tax returns can be filed semi-monthly, monthly, quarterly, biannually, or annually per state requirements. The sales or use tax rates vary from one state to another, with a range of 2.9 to 7.25% in most states. </Typography>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 1 &&
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Filing Tax Returns to HMRC</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary }}>
                      <Typography variant="h6" sx={{ color: colors.black, mt: 2, fontWeight: 'bold' }}>1.	VAT Return Filing</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '185px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ my: 2, fontSize: "14px" }}>You should register your business for VAT with HMRC once your VAT taxable turnover crosses £85000. Once registered, you get a VAT registration certificate with a VAT number and date to file your first VAT returns to HMRC. Typically, VAT returns are submitted to HMRC every three months, which is called your accounting period. VAT return records your three months activity for:</Typography>
                      <ul style={{ lineHeight: 2, fontSize: '14px', color: colors.textPrimary }}>
                        <li>Total sales and purchases.</li>
                        <li>VAT amount to be paid.</li>
                        <li>Correspondence address.</li>
                        <li>Reclaimable VAT amount.</li>
                        <li>VAT refund from HMRC.</li>
                      </ul>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>Even if there is no VAT to pay or reclaim, you must submit a VAT return to HMRC. </Typography>
                      <Typography variant='h6' sx={{ color: colors.black, my: 2, fontWeight: 'bold' }}>Filing Final VAT Returns</Typography>
                      <Typography sx={{ mb: 2 }}>In case you have your VAT registration cancelled, you must submit a final VAT return to HMRC.</Typography>
                      <Typography variant="h6" sx={{ color: colors.black, mt: 2, fontWeight: 'bold' }}>2.	Self-Assessment Tax Returns</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '310px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ my: 2 }}>Self-assessment is a system implemented by HMRC to collect income tax. Normally, this is tax is auto deducted from the personal incomes including wages, savings, and pensions. While people or businesses having other income must report it in tax returns either by filing online or by sending a paper form before the deadline. HMRC calculates what you owe based on what you had reported. You must pay your self-assessment bill by the 31st of January each year. How much tax you need to pay depends on the band of income tax you fall in.</Typography>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 2 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Filing Tax Returns to FBR</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Typography
                        sx={{ mt: 2, mb: 4, fontSize: "14px" }}
                      >
                        The Federal Board of Revenue (FBR) facilitates tax filing in Pakistan. Before filing tax returns, you must first register with FBR online through the Iris portal if you are an individual or by visiting the regional tax office if you are the principal officer of the Association of Person (AOP) and company.
                      </Typography>
                      <Typography
                        sx={{ mb: 4, fontSize: "14px" }}
                      >As you get registered with FBR, a National Tax Number (NTN) or registration number is provided to you. If you are registered as an individual, your CNIC number will be used as NTN, while in the case of AOP and company, a 7-digit NTN number is assigned after e-enrolment. Once you have registered with FBR, log in to your Iris portal to file your income tax returns.</Typography>
                      <Typography
                        sx={{ mb: 4, fontSize: "14px" }}
                      >To complete an online income tax return, you must fill the Return of Income form and Wealth Statement (statement of assets and liabilities) form. The submission success of these forms is confirmed once the forms are moved from the draft folder to the completed task.</Typography>

                    </Box>
                  </Box>
                }
              </Grid>
              <Grid sx={{ my: 7, bgcolor: colors.lightGray, p: 2 }}>
                <Typography variant="h5" color={colors.black}
                  sx={{ my: 2, fontWeight: 'bold' }}>What’s needed for company registration in Pakistan:</Typography>
                <ol style={{ lineHeight: 2.5, fontSize: '14px', color: colors.textPrimary }}>
                  <li>Decide a name and check its availability on the SECP website. Don’t forget to check for prohibited terms stated in section 10 of the act and regulation 4 of companies regulations 2017.</li>
                  <li>Details of the company director(s) or shareholders.</li>
                  <li>Correspondence address.</li>
                  <li>CNIC if you are a Pakistani national and passport if you are a foreigner.</li>
                  <li>Documents needed include Article of association and Memorandum of association.</li>
                  <li>Pay the registration fee using your debit/credit card.</li>
                </ol>
              </Grid>
            </Grid>
          </Box>
        </Grid >
      </Container >
    </Layout >
  )
}

export default FillingTaxReturn