import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, CardActions, Button, Chip } from '@mui/material';
import { BasicPlan, Checkmark, PremiumPlan, StarterPlan } from 'assets/Images';
import colors from 'app/style/colors';



const goldFeatures = [
  'Preparing and filing Article of Organization.',
  'One-year free Registered Agent Service',
  'Operating Agreement',
  'IRS Form 2553 ',
  'Online Dashboard Access',
  'Lifelong Company Alerts ',
  'Business bank account',
  'Consultation on Business Tax',
  'Banking Resolution',
  'Unlimited Phone and Email support',
  'EIN',
]
const platinumFeatures = [
  'Preparing and filing Article of Organization.',
  'One-year free Registered Agent Service',
  'Operating Agreement',
  'IRS Form 2553 ',
  'Online Dashboard Access',
  'Lifelong Company Alerts ',
  'Business bank account',
  'Consultation on Business Tax',
  'Banking Resolution',
  'Unlimited Phone and Email support',
  'EIN',
  'Templates of Business contract',
  'Expedited Company Filing',
  'Business Email and Domain Name',
]


function Pricing() {

  return (
    <Box sx={{ py: 7 }} >
      <Container>
        <Typography variant="body1" sx={{ color: '#919EAB', fontWeight: 600 }} >Pricing</Typography>
        <Typography variant="h3" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow' }} >Check Our Most Valued Packages</Typography>
        <Typography variant="h6" sx={{ color: colors.textPrimary }} >Get a Quotation to begin your e-Commerce journey with us</Typography>
        <Grid container spacing={2.5} justifyContent="center" alignItems="center" sx={{ my: 2 }} >
          <Grid item md={4}>
            <Card
              sx={{
                borderRadius: 3, boxShadow: '-4px 4px 24px 0 rgb(145 158 171 / 8%)', p: 3, pt: 5,
                cursor: 'pointer'
              }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box>
                    <Typography variant="h5" color="primary" gutterbottom sx={{ fontFamily: 'Barlow', fontWeight: 600, mb: 2 }}>
                      Silver
                    </Typography>
                    {/* <Typography variant="h4" component="div" sx={{ fontWeight: 600, fontFamily: 'Barlow' }}>
                      $29 <Typography component="span" color="secondary" >/month</Typography>
                    </Typography> */}
                  </Box>
                  <BasicPlan />
                </Box>
                <Typography sx={{ my: 1.5, fontWeight: 'bold' }} >
                  Benefit Our Basic Services
                </Typography>
                <Box sx={{ pt: 1, my: 2 }} >
                  <Typography sx={{ my: 1.2 }} color="secondary">
                    <Checkmark />
                    LLC (No LLC fee, pay the State fee only)
                    <ul style={{ fontSize: '14px' }}>
                      <li>Preparing and filing Article of Organization.</li>
                      <li>One-year free Registered Agent Service</li>
                    </ul>
                  </Typography>
                  <Typography sx={{ my: 1.2 }} color="secondary">
                    <Checkmark />
                    EIN
                  </Typography>
                  <Typography sx={{ my: 1.2 }} color="secondary">
                    <Checkmark />
                    Reseller Certificate
                  </Typography>
                  <Typography sx={{ my: 1.2 }} color="secondary">
                    <Checkmark />
                    Business Bank account
                  </Typography>
                  <Typography sx={{ my: 1.2 }} color="secondary">
                    <Checkmark />
                    Amazon Account creation
                  </Typography>

                </Box>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 1.5,
                    borderRadius: 2,
                    fontWeight: 600,
                  }}
                >
                  Get Started
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card sx={{ borderRadius: 3, boxShadow: '-16px 16px 56px -8px rgb(145 158 171 / 16%)', p: 3, pt: 5 }}>
              <CardContent>
                <Chip label="POPULAR" sx={{ mb: 2, borderRadius: 2, mt: '-10px', fontWeight: 600, fontFamily: 'Poppins', color: '#0067AA', bgcolor: 'rgba(0, 177, 237, 0.16)' }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box>
                    <Typography variant="h5" color="primary" gutterbottom sx={{ fontFamily: 'Barlow', fontWeight: 600, mb: 2 }}>
                      Gold
                    </Typography>
                    {/* <Typography variant="h4" component="div" sx={{ fontWeight: 600, fontFamily: 'Barlow' }}>
                      $59 <Typography component="span" color="secondary" >/month</Typography>
                    </Typography> */}
                  </Box>
                  <StarterPlan />
                </Box>
                <Typography sx={{ my: 1.5, fontWeight: 'bold' }}>
                  Get all that is essential
                </Typography>
                <Box sx={{ pt: 1, my: 2 }} >
                  <Typography component={'div'} sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    LLC (LLC + State fee)
                    <ul>
                      {goldFeatures.map((item, index) => (
                        <li key={index}>{item}</li>))}
                    </ul>

                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Reseller Certificate
                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Business Bank account
                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Amazon Account creation
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 1.5,
                    borderRadius: 2,
                    fontWeight: 600,
                  }}
                >
                  Get Started
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card sx={{ borderRadius: 3, boxShadow: '-16px 16px 56px -8px rgb(145 158 171 / 16%)', p: 3, pt: 5 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box>
                    <Typography variant="h5" color="primary" gutterbottom sx={{ fontFamily: 'Barlow', fontWeight: 600, mb: 2 }}>
                      Platinum
                    </Typography>
                    {/* <Typography variant="h4" component="div" sx={{ fontWeight: 600, fontFamily: 'Barlow' }}>
                      $99 <Typography component="span" color="secondary" >/month</Typography>
                    </Typography> */}
                  </Box>
                  <PremiumPlan />
                </Box>
                <Typography sx={{ my: 1.5, fontWeight: 'bold' }}>
                  Get all that is essential
                </Typography>
                <Box sx={{ pt: 1, my: 2 }} >
                  <Typography component={'div'} sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    LLC (LLC + State fee)
                    <ul>
                      {platinumFeatures.map((item, index) => (
                        <li key={index}>{item}</li>))}
                    </ul>
                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Reseller Certificate
                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Business Bank account
                  </Typography>
                  <Typography sx={{ my: 1.5 }} color="secondary">
                    <Checkmark />
                    Amazon Account creation
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 1.5,
                    borderRadius: 2,
                    fontWeight: 600,
                  }}
                >
                  Get Started
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Pricing;