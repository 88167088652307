import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Container, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward, Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';

//*import Component
import useAuth from 'app/hooks/useAuth';
import colors from 'app/style/colors';
import Images from 'assets/Images';

const useStyle = makeStyles({
  navItems: {
    marginLeft: '6px',
    marginRight: '6px',
    color: colors.secondary,
    padding: '10px 10px',
    textDecoration: 'none',
    transition: '0.3s ease-in-out',
    "&:hover": {
      color: colors.primary,
    },
    "&::before": {
      top: '0px',
      width: '6px',
      height: '6px',
      bottom: '0px',
      left: '-14px',
      content: '',
      display: 'block',
      margin: 'auto 0px',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: colors.primary,
    },
  },
  active: {
    color: colors.primary,
  },

})



function Header() {

  const { user, logout } = useAuth();
  const classes = useStyle();
  const { pathname } = useLocation();

  const [resourceShow, setResourceShow] = useState(false);

  //*Account Hover Section Constant*/
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };




  const arr = ["/us-formation", "/uk-formation", "/pakistan-formation", `/filling-tax-return`]

  //*Header Hide on Small Screen
  const [showResults, setShowResults] = useState(false)

  const menuList = (

    <Grid container spacing={0} alignItems="center">
      <Grid item xs={12} md={10.5} sx={{ textAlign: { xs: "center", md: "left" }, display: "flex", flexGrow: 1, flexDirection: { xs: "column", md: "row" } }}>

        <Link to={"/"} className={clsx(classes.navItems, { [classes.active]: pathname === '/', })} >
          Home
        </Link>

        {/* <Link to={"/service"}
          className={clsx(classes.navItems, { [classes.active]: pathname === '/service', })} >
          Resource Center
        </Link> */}
        <Link onMouseOver={() => setResourceShow(true)}
          to={""} className={clsx(classes.navItems, { [classes.active]: arr.indexOf(pathname) !== -1 })} >
          Service
        </Link>
        <Link to={"/learning"} className={clsx(classes.navItems, { [classes.active]: pathname === '/learning', })} >
          Learning
        </Link>
        <Link to={"/about-us"} className={clsx(classes.navItems, { [classes.active]: pathname === '/about-us', })} >
          About Us
        </Link>
        <Link to={"/contact-us"} className={clsx(classes.navItems, { [classes.active]: pathname === '/contact-us', })} >
          Contact Us
        </Link>

      </Grid>

      <Grid item xs={12} md={1.5}
        sx={{ textAlign: { xs: "center", md: "right" } }}
      >
        {user ? (
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}>

              <Tooltip title="My Profile">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ mt: -2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 40, height: 40, bgcolor: colors.primary }}></Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

              <MenuItem component={Link} to='/myprofile'>
                <Avatar /> My Profile
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => logout()}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Fragment>
        ) : (
          <Link to={"/login"} className={clsx(classes.navItems, { [classes.active]: pathname === '/login', })}

          >
            <Button variant='contained'>
              Login
            </Button>
          </Link>
        )}

      </Grid>
    </Grid>
  )

  return (
    <Box component="header" sx={{ py: 1.5, borderBottom: `2px solid ${colors.lightPrimary}` }}>
      <Container sx={{ position: "relative" }}>
        <Grid container spacing={0} alignItems="center">

          <Grid item xs={10} sm={11} md={1}>
            <Link to="/">
              <Typography
                component="img"
                src={Images.logo}
                alt="Mashr"
                sx={{
                  height: 50,
                  mr: 2
                }}
              />
            </Link>
          </Grid>

          <MenuIcon sx={{
            color: colors.primary,
            display: {
              xs: "block",
              md: "none"
            },
            fontSize: "40px"
          }}
            onClick={() => setShowResults(!showResults)}
          />
          {showResults ? (
            <Box
              sx={{
                width: {
                  md: '91.6%',
                  xs: '100%',

                }
              }}
            >
              {menuList}
            </Box>
          ) : (
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block"
                },
                width: '91.6%'
              }}
            >
              {menuList}
            </Box>
          )
          }
        </Grid>
        {resourceShow &&
          <Box
            onMouseLeave={() => setResourceShow(false)}
          >
            <Grid container
              sx={{
                borderRadius: 2, position: "absolute", top: 50, bgcolor: colors.white, boxShadow: 2,
                p: 2, width: "96%", display: 'flex', justifyContent: 'space-around', cursor: 'pointer', zIndex: 999,
              }}>

              <Grid item md={3}
                sx={{ py: 4 }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>Company Registration</Typography>
                <Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/us-formation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Company Formation in US </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/uk-formation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Company Formation in UK </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/pakistan-formation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Company Formation in Pakistan </Typography>
                  </Box>
                </Box>
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>State Fee</Typography>
                <Box sx={{ display: 'flex', ml: 2 }}>
                  <ArrowForward sx={{ color: colors.primary }} />
                  <Typography
                    onClick={() => setResourceShow(false)}
                    component={Link} to="/state-filing"
                    variant="body2"
                    sx={{
                      textDecoration: 'none',
                      color: colors.black,
                      ml: 1, mb: 1.5,
                      '&:hover': {
                        color: colors.primary,
                      }
                    }}
                  >State Fee</Typography>
                </Box>
              </Grid>
              <Grid item md={3}
                sx={{ py: 4 }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>Filling Tax Returns</Typography>
                <Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/filling-tax-return"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Filing Tax Returns to IRS  </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/filling-tax-return"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Filing Tax Returns to HMRC </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/filling-tax-return"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Filing Tax Returns to FBR </Typography>
                  </Box>
                </Box>
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>Marketing</Typography>
                <Box sx={{ display: 'flex', ml: 2 }}>
                  <ArrowForward sx={{ color: colors.primary }} />
                  <Typography
                    onClick={() => setResourceShow(false)}
                    component={Link} to="/marketing"
                    variant="body2"
                    sx={{
                      textDecoration: 'none',
                      color: colors.black,
                      ml: 1, mb: 1.5,
                      '&:hover': {
                        color: colors.primary,
                      }
                    }}
                  >Marketing </Typography>
                </Box>
              </Grid>
              <Grid item md={3}
                sx={{ py: 4 }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>Tax ID Registration</Typography>
                <Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/tax-id-registration-uk"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >VAT Registration in the UK </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/tax-id-registration-us"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Tax ID Registration in the US</Typography>
                  </Box>
                </Box>
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, mt: 6, mb: 2 }}>Virtual Bank Account Formation</Typography>
                <Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/virtual-bank-account-formation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Bank Account Formation</Typography>
                  </Box>

                </Box>
              </Grid>
              <Grid item md={3}
                sx={{ py: 4 }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: colors.primary, my: 2 }}>eCommerce Store Creation</Typography>
                <Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/eCommerce-store-creation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Amazon Account Creation </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/eCommerce-store-creation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >eBay ID Creation </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/eCommerce-store-creation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Etsy Store creation </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/eCommerce-store-creation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Daraz ID Creation </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', ml: 2 }}>
                    <ArrowForward sx={{ color: colors.primary }} />
                    <Typography
                      onClick={() => setResourceShow(false)}
                      component={Link} to="/eCommerce-store-creation"
                      variant="body2"
                      sx={{
                        textDecoration: 'none',
                        color: colors.black,
                        ml: 1, mb: 1.5,
                        '&:hover': {
                          color: colors.primary,
                        }
                      }}
                    >Shopify Store Creation</Typography>
                  </Box>

                </Box>
              </Grid>


            </Grid>
          </Box>
        }
      </Container >
    </Box >
  );
}

export default Header;