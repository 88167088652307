import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';

import Images from 'assets/Images';
import colors from 'app/style/colors';
import { Link } from 'react-router-dom';

function Provide() {
  return (
    <Box sx={{ py: 7 }} >
      <Container>
        <Typography variant="body1" sx={{ color: '#919EAB', fontWeight: 600 }} >Our Services</Typography>
        <Typography variant="h3" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow' }} >We Provide</Typography>
        <Typography variant="h6" sx={{ color: colors.textPrimary }} >Our most ordered services</Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ my: 2 }} >
          <Grid item md={4}>
            <Card
              sx={{
                borderRadius: 3, boxShadow: 4,
                height: 500,
                px: 1, py: 2, textAlign: 'center',

              }}>
              <CardContent sx={{ height: 420 }}>
                <img src={Images.companyFormation} alt="IT Services" width='100%' />
                <Typography variant="h6" gutterbottom sx={{ textTransform: 'capitalize', fontFamily: 'Barlow', fontWeight: 600, my: 2.5 }}>
                  Company Formation
                </Typography>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Company formation in the US
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Company formation in the UK
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Company formation in Pakistan
                </li>
              </CardContent>
              <IconButton
                component={Link} to="/us-formation"
                color="primary" aria-label="upload picture" >
                <ArrowRightAlt
                  sx={{ fontSize: '32px' }}
                />
              </IconButton>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              sx={{
                borderRadius: 3, boxShadow: 4,
                height: 500,
                px: 1, py: 2, textAlign: 'center',

              }}>
              <CardContent sx={{ height: 420 }}>
                <img src={Images.ecommerceIdRegistration} alt="IT Services" width='90%' />
                <Typography variant="h6" gutterbottom sx={{ textTransform: 'capitalize', fontFamily: 'Barlow', fontWeight: 600, my: 2.5 }}>
                  E-commerce platform ID creation
                </Typography>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Amazon ID Creation
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  eBay ID Creation
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Etsy ID Creation
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Daraz ID Creation
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Shopify Store Creation
                </li>
              </CardContent>
              <IconButton
                component={Link} to="/eCommerce-store-creation"
                color="primary" aria-label="upload picture" >
                <ArrowRightAlt
                  sx={{ fontSize: '32px' }}
                />
              </IconButton>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              sx={{
                borderRadius: 3, boxShadow: 4,
                height: 500,
                px: 1, py: 2, textAlign: 'center',

              }}>
              <CardContent sx={{ height: 420 }}>
                <img src={Images.taxIdRegistration} alt="IT Services" width='100%' />
                <Typography variant="h6" gutterbottom sx={{ textTransform: 'capitalize', fontFamily: 'Barlow', fontWeight: 600, my: 2.5 }}>
                  Tax ID Registration
                </Typography>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  VAT filing in the UK
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  EIN for the US
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  ITIN for the US
                </li>
                <li
                  style={{ fontSize: '18px', marginTop: 1.5, color: colors.secondary, textAlign: 'left' }} >
                  Reseller permits
                </li>
              </CardContent>
              <IconButton
                component={Link} to="/tax-id-registration-uk"
                color="primary" aria-label="upload picture" >
                <ArrowRightAlt
                  sx={{ fontSize: '32px' }}
                />
              </IconButton>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}

export default Provide;