import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Container, IconButton, Divider } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Images from 'assets/Images';
import colors from 'app/style/colors';
import { CurrentYear } from 'app/utils';

const useStyle = makeStyles({
  navItems: {
    cursor: 'pointer',
    padding: '8px 0px',
    color: colors.textPrimary
  },
})

function Footer() {

  const classes = useStyle();

  return (
    <Fragment>

      <Box sx={{ py: 7 }}>
        <Container>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item md={4}>
              <Link to="/">
                <Typography
                  component="img"
                  src={Images.logo}
                  alt="Mashr"
                  sx={{
                    height: 50,
                    mr: 2
                  }}
                />
              </Link>
              <Typography variant="body2" sx={{ mt: 1, mb: 2.5, color: colors.textPrimary }} >We Help you set up & grow a business with infinite opportunities in the top E-commerce marketplaces</Typography>
              <Typography variant="h6" sx={{ pt: 3, fontWeight: 700, fontFamily: 'Barlow' }} >Social</Typography>
              <Box sx={{ py: 2 }}>
                <Typography component="a" href="https://www.facebook.com/mashrco/" target="_blank" >
                  <IconButton sx={{ mr: 1 }}>
                    <Facebook color="primary" />
                  </IconButton></Typography>
                <Typography component="a" href="https://www.instagram.com/accounts/login/?next=/mashrco/" target="_blank" >
                  <IconButton sx={{ mr: 1 }}>
                    <Instagram color="primary" />
                  </IconButton></Typography>
                {/* <IconButton sx={{ mr: 1 }}>
                  <LinkedIn color="primary" />
                </IconButton>
                <IconButton sx={{ mr: 1 }}>
                  <Twitter color="primary" />
                </IconButton> */}
              </Box>
              {/* <Box sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ pb: 1, fontWeight: 700, fontFamily: 'Barlow' }} >Learning</Typography>
                <Typography variant="body2" className={classes.navItems} >Amazon Courses</Typography>
                <Typography variant="body2" className={classes.navItems} >Language Courses</Typography>
                <Typography variant="body2" className={classes.navItems} >Computer Courses</Typography>
              </Box> */}
            </Grid>
            <Grid item md={6} container spacing={1} justifyContent="space-between" >
              <Grid item md={5} sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ pb: 1, fontWeight: 700, fontFamily: 'Barlow' }} >Company Formation</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/us-formation" variant="body2" className={classes.navItems} >Company Formation in US</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/uk-formation" variant="body2" className={classes.navItems} >Company Formation in UK</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/pakistan-formation" variant="body2" className={classes.navItems} >Company Formation in Pakistan</Typography>
              </Grid>
              <Grid item md={5} sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ pb: 1, fontWeight: 700, fontFamily: 'Barlow' }} >TAX ID Registration</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/tax-id-registration-uk" variant="body2" className={classes.navItems} >Tax ID Registration in the UK</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/tax-id-registration-us" variant="body2" className={classes.navItems} >VAT Registration in the US</Typography>
                {/* <Typography variant="body2" className={classes.navItems} >EIN for US</Typography>
                <Typography variant="body2" className={classes.navItems} >ITIN for US</Typography>
                <Typography variant="body2" className={classes.navItems} >Reseller Permits</Typography> */}
              </Grid>
              <Grid item md={5} sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ pb: 1, fontWeight: 700, fontFamily: 'Barlow' }} >Online Bank Account Formation</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/virtual-bank-account-formation" variant="body2" className={classes.navItems} >Transfer Wise</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/virtual-bank-account-formation" variant="body2" className={classes.navItems} >Mercury</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/virtual-bank-account-formation" variant="body2" className={classes.navItems} >Brex</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/virtual-bank-account-formation" variant="body2" className={classes.navItems} >Hyper Wallet</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/virtual-bank-account-formation" variant="body2" className={classes.navItems} >Payoneer</Typography>
              </Grid>
              <Grid item md={5} sx={{ py: 1 }}>
                <Typography variant="h6" sx={{ pb: 1, fontWeight: 700, fontFamily: 'Barlow' }} >Filling of Tax</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/filling-tax-return" variant="body2" className={classes.navItems} >Fill Tax Returns to IRS</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/filling-tax-return" variant="body2" className={classes.navItems} >Fill Tax Returns to HMRC</Typography>
                <Typography sx={{ textDecoration: 'none', display: 'block', '&:hover': { color: colors.primary } }} component={Link} to="/filling-tax-return" variant="body2" className={classes.navItems} >Fill Tax Returns to FBR</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Divider />

      <Box sx={{ py: 1.5 }}>
        <Container>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item md={6}>
              <Typography variant="body2" sx={{ my: 1, color: colors.textPrimary }} >© <CurrentYear />. All rights reserved</Typography>
            </Grid>
            <Grid item md={6} sx={{ textAlign: 'right' }} >
              <Typography variant="body2" component="span" sx={{ my: 1, mx: 1, color: colors.textPrimary }} >Help Center</Typography>
              <Typography variant="body2" component="span" sx={{ my: 1, mx: 1, color: colors.textPrimary }} >Term of Service</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Fragment>
  );
}

export default Footer;