import React from 'react';
import { Container, Typography, Grid, Box, CardMedia, CardContent, Divider } from '@mui/material';

import Images from 'assets/Images';
import colors from 'app/style/colors';

function Explore() {
  return (
    <Box sx={{ pt: 6, pb: 15 }}>
      <Container>
        <CardMedia sx={{
          borderRadius: 3, height: 400,
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${Images.working})`,
        }} >
          <CardContent>
            <Typography variant="h3"
              sx={{ color: colors.white, fontWeight: 'bold', textAlign: 'center', mt: 12 }}
            >What a perfect day to visit our website!! Check out our sizzling hot deals of the day that are going to end in just a few hours.</Typography>
          </CardContent>
        </CardMedia>


        <Box sx={{ my: 4 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ my: 0 }}>
            <Grid item md={4} sx={{ textAlign: 'center' }}>

              <img src={Images.goldMine} alt="Gold Mining"
                style={{ width: '115px', cursor: 'pointer' }}
              />
              <Box sx={{ py: 1 }}>
                <Typography variant="h5" sx={{ my: 1.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.black }} >A Gold Mine to go with</Typography>
                <Typography variant="body1" sx={{ color: colors.textPrimary }} >We are offering free eBay account sign-up for you when you order our A-Z ID creation service.</Typography>
              </Box>
            </Grid>
            <Grid item md={4} sx={{ textAlign: 'center' }}>
              <img src={Images.discount} alt="Discount"
                style={{ width: '90px', cursor: 'pointer' }}
              />
              <Box sx={{ py: 1 }}>
                <Typography variant="h5" sx={{ my: 1.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.black }} >A Company on discount</Typography>
                <Typography variant="body1" sx={{ color: colors.textPrimary }} >Get 20% off on our LLC registration fee.</Typography>
              </Box>
            </Grid>
            <Grid item md={4} sx={{ textAlign: 'center' }}>
              <img src={Images.socialMediaMarketing} alt="Social Media Marketing"
                style={{ width: '110px', cursor: 'pointer' }}
              />
              <Box sx={{ py: 1 }}>
                <Typography variant="h5" sx={{ my: 1.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.black }} >Go Unstoppably Social </Typography>
                <Typography variant="body1" sx={{ color: colors.textPrimary }} >Get up to 20% off on our social media marketing services and let people know your business.</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Divider sx={{ py: 6 }} />
            <Typography variant='h5' sx={{ fontWeight: 'bold', mt: 8, textAlign: 'center', color: colors.black }}>What’s better than starting with something free at the very start of your e-Commerce business? </Typography>
            <Typography variant='body1' sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', color: colors.textPrimary }}> So, delay no more as the discount deals don’t last long.</Typography>
            <Divider sx={{ my: 6 }} />
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={12} md={5.5}>
                <Box
                  sx={{
                    clipPath: `circle(100.2% at 17% 50%)`, bgcolor: colors.primary,
                    width: '100%', height: 200, textAlign: 'center',
                    borderRadius: '10px 0  0 10px'
                  }}>
                  <img src={Images.help} alt="help"
                    style={{ width: '40%' }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ my: 6 }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: colors.black }}>We are just a call/message away from you</Typography>
                  <Typography variant="body1" sx={{ color: colors.textPrimary }}>We have got an amazing customer support team who absolutely love to help you out with whatever questions you may have in your mind about any of the services.
                    So don’t hesitate to ping us.</Typography>
                </Box>
              </Grid>
            </Grid>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 7, textAlign: 'center', color: colors.black }}>We love how our customers praise and appreciate us when they get what was promised. It shows we are headed in the right direction.</Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', my: 2.5, textAlign: 'center', color: colors.white, bgcolor: colors.primary }}>We offer a 100% money-back guaranty </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center', color: colors.textPrimary }}>What you pay to us is yours unless we deliver what we had promised to deliver. So, don’t worry about what will happen in case you change your mind or something about our service is not up to the mark.</Typography>
          </Grid>

        </Box>
      </Container>
    </Box>
  );
}

export default Explore;